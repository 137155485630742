.footerDivStyles {
    font-family: 'Ariel', 'Helvetica', 'sans-serif';
    color: #032B43;
    height: 2.5rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 0.5rem 0 0.5rem;
    width: 100%;
    bottom: 0;
}

.contactIcons {
    display: flex;

}

.p {
    font-size: 0.9rem;
    text-align: center;
    margin-left: 10%;
    margin-right: 10%;
}

.p p {
    font-weight: 300;
}
