.nav {
    background-color: #032B43;
    height: 4.5rem;
    font-size: 1.1rem;
    font-weight: 300;
    display: flex;
    width: 100%;
    top: 0;
    z-index: 1000;
}

.navContainer {
    width: 70rem;
    display: flex;
    justify-content: space-between;
    margin-left: auto;
    margin-right: auto;
}

.logoContainer {
    display: flex;
    align-items: center;
}

.logoImage {
    height: 3rem;
    margin-left: -1rem;
}

.logo {
    display: flex;
    align-items: center;
    color: #FF9C1A;
}

.logo p {
    font-size: 1.5rem;
    font-family: 'Oswald';
    font-weight: 600;
}

.navContainer a {
    background-color: none;
    cursor: pointer;
    text-decoration: none;
    color: #FF9C1A;
    font-family: 'Oswald';
}

.ul {
    display: flex;
    gap: 1.5rem;
    margin: 0;
    padding: 0;
    font-size: 1.2rem;
}

.ul a {
    text-decoration: none;
    transition: 0.3s ease-in-out;
}

.ul a:hover {
    color: #DCEFFF;
}

.navMenuItemsContainer {
    display: flex;
    align-items: center;
}

.faBtnIcons {
    display: none;
}

@media only screen and (max-width: 90rem) {
    .navContainer {
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media only screen and (max-width: 1024px) {
    .logoImage {
        height: 2.85rem;
    }
    .logo p {
        font-size: 1.35rem;
    }
    .ul {
        display: none;
    }
    .dropdownOpen {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        text-align: center;
        position: fixed;
        top: 4.5rem;
        right: 0;
        width: 100%;
        height: 17rem;
        background: #032B43;
        box-shadow: 0 40px 60px rbga(0, 0, 0, 0.1);
    }
    .dropdownOpen a {
        box-sizing: border-box;
        width: 70%;
        border-top: solid thin #DCEFFF;
        padding-top: 1rem;
    }
    .faBtnIcons {
        display: flex;
        align-items: center;
        color: #FF9C1A;
    }
    .faBtnIcons:hover {
        color: #DCEFFF;
        cursor: pointer;
    }
    .closeIcon {
        transform: rotate(-90deg);
        transition: transform 0.35s ease-in-out;
    }
    .openIcon {
        transform: rotate(0deg);
        transition: transform 0.35s ease-in-out;
    }
}

@media only screen and (max-width: 768px) {
    .logoImage {
        height: 2.75rem;
    }
    .logo p {
        font-size: 1.2rem;
    }
    .navContainer {
        margin-left: 12%;
        margin-right: 12%;
    }
    .ul a {
        width: 76%;
    }
}

@media only screen and (max-width: 480px) {
    .logoImage {
        height: 2.5rem;
    }
    .logo p {
        font-size: 1.05rem;
    }
    .navContainer {
        margin-left: 9%;
        margin-right: 9%;
    }
    .ul {
        margin: 0;
        right: 0;
        top: 0;
        width: 100%;
        height: 100%;
        border-top: none;
    }
    .faBtnIcons {
        z-index: 1000;
    }
}
