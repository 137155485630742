.mainContent {
    display: flex;
    flex-direction: column;
    width: 70rem;
    margin-left: auto;
    margin-right: auto;
    color: #032B43;
}

.aboutContainer {
    border: 1px solid #032B43;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 2rem;
    border-radius: 12px;
    margin-top: 6rem;
    margin-bottom: 6rem;
    max-width: 70rem;
}

.greeting {
    text-align: center;
    line-height: 1.3rem;
    font-size: 1.75rem;
    text-decoration: underline;
}

.about {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content:space-around;
    padding: auto;
    max-width: 70rem;
    gap: 1rem;
}

.aboutText {
    display: flex;
    flex-direction: column;
    flex: 0 0 100%;
    justify-content: center;
    text-align: center;
    max-width: 37rem;
    font-size: 1.3rem;
}

.videoDemoText {
    font-size: 1.3rem;
}

.headshot {
    max-width: 20rem;
    height: 100%;
    border-radius: 12px;
}

.projects {
    max-width: 70rem;
    margin-bottom: 6rem;
    border: 1px solid #032B43;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 2rem;
    border-radius: 12px;
}

.sectionHeading {
    font-size: 1.65rem;
}

.projects h2 {
    text-align: center;
    line-height: 1.3rem;
}

.details {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.projectDetails {
    font-size: 1.25rem;
    text-align: center;
    max-width: 24rem;
    margin: 0 2rem 0 2rem;
}

.projectLink {
    font-size: 1.55rem;
}

.videoDemo {
    position: relative;
    padding-bottom: 56.25%;
    height: 0;
}

.video {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    border-radius: 12px;
}

.githubLink {
    font-size: 1.2rem;
}

.skills {
    max-width: 70rem;
    margin-bottom: 6rem;
    border: 1px solid #032B43;
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    padding: 2rem;
    border-radius: 12px;
}

.skills h2 {
    text-align: center;
    line-height: 1.3rem;
}

.skillsIcons {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
}

.icons {
    height: 2.5rem;
    width: 2.5rem;
    margin: 2rem 3rem 3rem 3rem;
}

@media only screen and (max-width: 90rem) {
    .mainContent {
        margin-left: 15%;
        margin-right: 15%;
    }
}

@media only screen and (max-width: 1024px) {
    .aboutContainer {
        margin-top: 5rem;
        margin-bottom: 5rem;
    }
    .greeting {
        font-size: 1.7rem;
    }
    .aboutText, .videoDemoText {
        font-size: 1.25rem;
    }
    .sectionHeading {
        font-size: 1.6rem;
    }
    .projects {
        margin-bottom: 5rem;
    }
    .projectLink {
        font-size: 1.5rem;
    }
    .githubLink {
        font-size: 1.1rem;
    }
    .skills {
        margin-bottom: 5rem;
    }
}

@media only screen and (max-width: 768px) {
    .mainContent {
        margin-left: 12%;
        margin-right: 12%;
    }
    .greeting {
        font-size: 1.6rem;
    }
    .aboutText, .videoDemoText {
        font-size: 1.15rem;
    }
    .headshot {
        max-width: 17.5rem;
        height: 100%;
    }
    .sectionHeading {
        font-size: 1.5rem;
    }
    .projectDetails {
        font-size: 1.15rem;
    }
    .projectLink {
        font-size: 1.4rem;
    }
    .githubLink {
        font-size: 1rem;
    }
    .skillsIcons {
        justify-content: center;
    }
    .icons {
        height: 2.25rem;
        width: 2.25rem;
        margin: 1.75rem 2.75rem 2.75rem 2.75rem;
    }
    .aboutContainer {
        margin-top: 4rem;
        margin-bottom: 4rem;
    }
    .projects {
        margin-bottom: 4rem;
    }
    .skills {
        margin-bottom: 4rem;
    }
}

@media only screen and (max-width: 480px) {
    .mainContent {
        margin-left: 9%;
        margin-right: 9%;
    }
    .headshot {
        max-width: 12rem;
        height: 100%;
    }
    .greeting {
        font-size: 1.5rem;
    }
    .sectionHeading {
        font-size: 1.4rem;
    }
    .aboutText, .videoDemoText {
        font-size: 1.05rem;
    }
    .icons {
        height: 2.15rem;
        width: 2.15rem;
        margin: 1.65rem 2.5rem 2.65rem 2.5rem;
    }
    .aboutContainer {
        margin-top: 3rem;
        margin-bottom: 3rem;
    }
    .projects {
        margin-bottom: 3rem;
    }
    .projectLink {
        font-size: 1.3rem;
    }
    .githubLink {
        font-size: 0.9rem;
    }
    .skills {
        margin-bottom: 3rem;
    }
}

@media only screen and (max-width: 380px) {
    .icons {
        margin: 1.65rem 1.75rem 2.25rem 1.75rem;
    }
}
