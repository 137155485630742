* {
    font-family: 'Lora', Georgia, Times, serif;
}

html {
    overflow-x: hidden;
    height: 100%;
    overscroll-behavior: none;
}

body {
    background-color: #dcefff;
    margin: 0;
    overflow-x: hidden;
    height: 100%;
    overscroll-behavior: none;
}

header {
    width: 100%;
    margin: 0 auto;
    display: flex;
}

main {
    width: 100%;
    margin: 0 auto;
    display: flex;
}
