.contact {
    font-size: 1.1rem;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    gap: .85rem;
    align-items: center;
  }

a:hover {
    color: #FF9C1A;
}

  .FontAwesomeIcon {
    font-size: 1.23rem;
    color: #032B43;
}

@media only screen and (max-width: 900px) {
    .contact {
        font-size: 1rem;
        gap: .75rem;
        
    }
}
